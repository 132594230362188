<template>
  <DashboardTemplate>
    <div id="project">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }" class="text-over clamp1"
          >หน้าแรก</el-breadcrumb-item
        >
        <el-breadcrumb-item class="text-over clamp1"
          >โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</el-breadcrumb-item
        >
      </el-breadcrumb>
      <h1>โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</h1>
      <p class="writtenBy">
        <b>เขียนและเรียบเรียงโดย</b><br />
        ผู้ช่วยศาสตราจารย์ธัญญพัทธ์ มุ่งพัฒนสุนทร<br />
        อาจารย์ประจำสาขาพัฒนาสังคม คณะรัฐศาสตร์และสังคมศาสตร์ มหาวิยาลัยพะเยา<br />
        เผยแพร่วันที่ 10 พฤษภาคม พ.ศ.2565
      </p>
      <video width="650" height="450" controls>
        <source
          :src="require('../assets/video/project1.mp4')"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div class="detail">
        <h2>ที่มาและความสำคัญของโครงการ</h2>
        <p class="indent">
          โครงการ “ทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์”
          มีความตั้งใจในการดึงทุนชุมชน โดยเฉพาะในด้านทุนวัฒนธรรม ได้แก่ ประเพณี
          พิธีกรรม ความเชื่อ วิถีชีวิต ฯลฯ
          ของชุมชนในแต่ละท้องถิ่นที่เข้าร่วมโครงการฯ
          มาพัฒนาสู่ผลิตภัณฑ์หัตถกรรมที่สร้างสรรค์มาจากการเชื่อมโยงทุนเหล่านี้
          โดยองค์ประกอบพื้นฐานของตัวผลิตภัณฑ์จะเกิดจากหัตถกรรมที่มีอยู่ในพื้นที่ชุมชนที่คัดสรรเพื่อเข้าร่วมโครงการ
          พร้อมกับทำการพัฒนาทักษะผู้ผลิต
          หรือผู้ประกอบการในชุมชนให้มีทักษะทางด้านกระบวนการคิดเชิงออกแบบ หรือ
          Design Thinking
          ผ่านกระบวนการฝึกปฏิบัติในการออกแบบหรือสร้างสรรค์ผลิตภัณฑ์ของตนเองโดยการถึงทุนวัฒนธรรมในชุมชนของตนเองมาสร้างเป็นอัตลักษณ์ให้กับผลิตภัณฑ์
          และการฝึกทักษะในด้านการคิดต้นทุน การทำการตลาด
          การสร้างสื่อสมัยใหม่ด้วยเทคนิคการถ่ายภาพ และถ่ายคลิป
          และการจัดทำประชาสัมพันธ์ผลิตภัณฑ์ที่เหมาะสมกับกลุ่มผู้ซื้อแต่ละกลุ่มในช่องทางออนไลน์
        </p>
        <p class="indent">
          จุดเด่นของโครงการมิใช่เพียง 1)
          สร้างนวัตกรรมหัตถกรรมสร้างสรรค์ที่เชื่อมโยงกับมิติวัฒนธรรมชุมชน 2)
          สร้างนวัตกรชุมชนด้วยการพัฒนาศักยภาพวิสาหกิจหรือผู้ประกอบการด้านหัตถกรรมในชุมชนให้สามารถออกแบบและสร้างอัตลักษณ์ให้ผลิตภัณฑ์ชุมชน
          จัดทำสื่อประชาสัมพันธ์ ทำการตลาดและจัดจำหนายในออนไลน์แพลตฟอร์มหรือ
          Social Media ที่กำลังเป็นที่นิยมได้
          แต่หากทีมนักวิจัยเล็งเห็นถึงความสำคัญในการสืบสานเรื่องราววิถีชีวิตของผู้ผลิตที่เป็นผู้สืบสานหรือสืบถอดในการผลิตหัตถกรรมเหล่านี้
          ควรได้รับการทำนุบำรุงหรือธำรงเรื่องราวควบคู่ไปกับตัวผลิตภัณฑ์
          ดังนั้นนวัตกรรมที่จะเกิดขึ้นเพื่อจะสานต่อสิ่งเหล่านี้ทีมผู้ดำเนินโครงการจึงจะ
          3) จัดทำแพลตฟอร์มหรือพื้นที่ชุมชนออนไลน์นั่นก็คือเว็บไซด์
          www.calpcenter.com
          ที่จะเป็นแหล่งรวบรวมสินค้าหัตถกรรมล้านนาสร้างสรรค์เข้าไว้กับเรื่องราว
          เรื่องเล่า ศิลปะ วัฒนธรรม ประเพณี วิถีชีวิต ฯลฯ
          ของคนในชุมชนผูกเข้าไว้กับตัวผลิตภัณฑ์อีกด้วย
        </p>
        <el-row :gutter="40">
          <el-col :xs="24" :sm="12">
            <div @click="$router.push('/community/1')" class="card-cummunity">
              <el-image :src="require('../assets/project/1.jpg')" fit="cover" />
              <p>ผลิตภัณฑ์ไทลื้อวัดหย่วนจากโครงการฯ</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div @click="$router.push('/product/ng1')" class="card-cummunity">
              <el-image :src="require('../assets/project/3.jpg')" fit="cover" />
              <p>ผลงานชุมชนแม่อิงจากโครงการฯ</p>
            </div>
          </el-col>
        </el-row>
        <p class="indent">
          ชุมชนเป้าหมายในการดำเนินโครงการครั้งนี้ คือ 1)
          ชุมชนไทลื้อวัดหย่วนเชียงคำ จากสมาชิกกลุ่มศูนย์วัฒนธรรมไทลื้อวัดหย่วน
          ต.หย่วน อ.เชียงคำ จ.พะเยา และ 2) ชุมชนแม่อิง ต.แม่อิง อ.ภูกามยาว
          จ.พะเยา ทั้งสองชุมชนมีกระบวนการทำงานในการพัฒนาผลิตภัณฑ์ ผลงาน
          หรือผลผลิตที่แตกต่างกันไปเนื่องจากระดับของทักษะของคนทั้งสองกลุ่มและบริบทของสังคมชุมชนมีความแตกต่างกัน
          ดังนั้น ในส่วนของชุมชนไทลื้อวัดหย่วน
          ได้ใช้กระบวนฝึกอบรมการคิดเชิงออกแบบ หรือ Design Thinking
          เป็นกระบวนการหลักในการฝึกอบรมเชิงปฏิบัติการเพื่อใช้ในการพัฒนาผลิตภัณฑ์
          หรือ Prototype ให้ตรงกับกลุ่มผู้ซื้อหรือผู้ใช้เป็นหลัก
          และในส่วนของชุมชนแม่อิง
          ได้ใช้กระบวนการพัฒนาผลผลิตหรือผลงานด้วยกระบวนการมีส่วนร่วมในแบบปรึกษาหารือและติดตามประเมินผลเกี่ยวกับความจำเป็นและความต้องการของผู้ประกอบการในชุมชนเป็นหลัก
        </p>
        <p class="indent">
          โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์ได้รับทุนสนับสนุนจากสำนักงานนวัตกรรมแห่งชาติ
          (NIA)
          ผ่านการบริหารจัดการจากสถาบันนวัตกรรมและถ่ายทอดเทคโนโลยีมหาวิทยาลัยพะเยา
          ภายใต้โครงการหน่วยขับเคลื่อนนวัตกรรมเพื่อสังคม (Social Innovation
          Driving Unit: SID) ประจำพื้นที่ภาคเหนือตอนบน 2 มหาวิทยาลัยพะเยา
          ประจำปีงบประมาณ พ.ศ. 2564
        </p>

        <div class="support">
          <el-row :gutter="40" type="flex" class="support">
            <el-col :span="24" :sm="8">
              <el-image
                :src="require(`../assets/support/3.png`)"
                fit="contain"
              />
            </el-col>
            <el-col :span="24" :sm="8">
              <el-image
                :src="require(`../assets/support/2.png`)"
                fit="contain"
              />
            </el-col>
            <el-col :span="24" :sm="8">
              <el-image
                :src="require(`../assets/support/1.png`)"
                fit="contain"
              />
            </el-col>
          </el-row>
        </div>
        <p class="indent">
          ทีมนักวิจัยที่ร่วมดำเนินโครงการประกอบไปด้วย 1)
          ผู้ช่วยศาสตราจารย์ธัญญพัทธ์ มุ่งพัฒนสุนทร หัวหน้าโครงการ
          อาจารย์ประจำสาขาพัฒนาสังคม คณะรัฐศาสตร์และสังคมศาสตร์ มหาวิทยาลัยพะเยา
          ผู้ริเริ่มในการที่จะจัดทำโครงการในครั้งนี้ด้วยการนำศาสตร์ความรู้ที่ตนเองมีความเชี่ยวชาญและสนใจเกี่ยวกับอัตลักษณ์ชุมชน
          จากประสบการณ์การทำงาน
          และจากศาสตร์ความรู้ที่ได้จบการศึกษามาทางด้านสังคมวิทยาและมานุษยวิทยา
          ตลอดจนกรอบแนวคิดการพัฒนาสังคมชุมชนมาปรับประยุกต์ใช้ในโครงการครั้งนี้
          2) ดร.วิวัย สุขเจริญ เกษแก้ว ผู้ร่วมโครงการ
          อาจารย์ประจำสาขาการสื่อสารใหม่ คณะบริหารธุรกิจและนิเทศศาสตร์
          มหาวิทยาลัยพะเยา
          ผู้เชี่ยวชาญในด้านการสร้างเนื้อหาประชาสัมพันธ์ในสื่อสารสนเทศสมัยใหม่
          มีบทบาทในการช่วยเสริมทักษะทางด้านการผลิตสื่อประชาสัมพันธ์ให้กับผลิตภัณฑ์
          และ 3) ดร.กิตติศักดิ์ ศรีไพโรจน์ ร่วมโครงการ อาจารย์ประจำสาขาการตลาด
          คณะบริหารธุรกิจและนิเทศศาสตร์ มหาวิทยาลัยพะเยา
          ผู้เชี่ยวชาญในด้านการทำโมเดลธุรกิจ การคิดต้นทุน และการทำการตลาด
          มีบทบาทในการช่วยเสริมทักษะทางด้านการวางแผนธุรกิจและการทำการตลาดออนไลน์ให้กับผู้เข้าร่วมโครงการในครั้งนี้
        </p>

        <div class="team">
          <el-row :gutter="40">
            <el-col :sm="24" :md="8">
              <div class="card">
                <el-avatar
                  :src="require(`../assets/team/1.jpg`)"
                  fit="cover"
                  :size="200"
                ></el-avatar>
                <p class="name">ผศ.ธัญญพัทธ์ มุ่งพัฒนสุนทร</p>
                <p class="position">หัวหน้าโครงการฯ</p>
                <p class="motto">
                  “การทำงานชุมชนจะสำเร็จไปด้วยดีไม่ได้ถ้าหากขาดความร่วมมือจากทุกๆ
                  ฝ่าย
                  โดยเฉพาะคนในชุมชนที่เราต้องให้เกียรติและเคารพซึ่งกันและกันเสมือนเป็นหนึ่งในนักวิจัยที่ไม่ต่างจากเรา”
                </p>
              </div>
            </el-col>
            <el-col :sm="24" :md="8">
              <div class="card">
                <el-avatar
                  :src="require(`../assets/team/2.jpg`)"
                  fit="cover"
                  :size="200"
                ></el-avatar>
                <p class="name">ดร.วิวัน สุขเจริญ เกษแก้ว</p>
                <p class="position">นักวิจัยร่วม</p>
                <p class="motto">
                  “ทำในสิ่งที่รัก รักในสิ่งที่ทำ นำวิชาการ สู่งานพัฒนาชุมชน”
                </p>
              </div>
            </el-col>
            <el-col :sm="24" :md="8">
              <div class="card">
                <el-avatar
                  :src="require(`../assets/team/3.jpg`)"
                  fit="cover"
                  :size="200"
                ></el-avatar>
                <p class="name">ดร.กิตติศักดิ์ ศรีไพโรจน์</p>
                <p class="position">นักวิจัยร่วม</p>
                <p class="motto">
                  “ความคิดสร้างสรรค์และการลงมือปฏิบัติ
                  คือการพัฒนาทักษะแห่งความรู้”
                </p>
              </div>
            </el-col>
          </el-row>
        </div>

        <p class="indent">
          นอกจากทีมงานที่กล่าวมาข้างต้น
          กระบวนการฝึกอบรมเชิงปฏิบัติการในโครงการนี้
          ได้มีการจัดกระบวนการเรียนรู้ให้กับผู้เข้าร่วมโครงการที่เป็นผู้ผลิตหรือผู้ประกอบการในชุมชนไทลื้อวัดหย่วน
          โดยเชิญวิทยากรผู้เชี่ยวชาญในด้านภูมิปัญญาชุมชน
          และผู้เชี่ยวชาญทางด้านเทคนิคการถ่ายภาพ ถ่ายคลิป
          และตัดต่อด้วยโทรศัพท์เคลื่อนที่มีระบบสมาร์ทโฟนในการใช้งานเพื่อการประชาสัมพันธ์ผลิตภัณฑ์มาให้ความรู้และพัฒนาทักษะในด้านดังกล่าว
        </p>
      </div>
    </div>
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from "@/template/DashboardTemplate.vue";

export default {
  components: {
    DashboardTemplate,
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/element-variables.scss";
#project {
  padding: 40px 10vw 40px 10vw;
  text-align: center;
  h1 {
    font-size: 30px;
    margin: 40px 0 50px 0;
    letter-spacing: 0;
  }
  .writtenBy {
    text-align: right;
    font-style: italic;
    font-size: 14px;
  }
  .detail {
    padding-top: 10px;
    text-align: left;
    p.indent {
      text-indent: 3em;
      text-align: justify;
      word-break: break-all;
    }
    .card-cummunity {
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      .el-image {
        height: 300px;
        width: 100%;
      }

      @media (max-width: 767px) {
        height: auto;
        padding: 10px 0;
      }
    }

    .card-cummunity:hover {
      p {
        color: $--color-primary;
      }
    }
    .team {
      padding: 20px 0;
      text-align: center;
    }
    .support {
      padding: 20px 0;
      text-align: center;
      .el-image {
        height: 120px;
      }
    }
  }
}
</style>